import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { oktaAuth } from './auth';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './redux/reducers';
import thunk from 'redux-thunk';
import { loadState, saveState } from './redux/localStorage';

const persistedStateReducer = loadState();

const store = createStore(
  rootReducer,
  persistedStateReducer,
  applyMiddleware(thunk),
);

store.subscribe(() => {
  saveState(store.getState());
});

const AppRouter = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    if (originalUri) {
      console.log('restoreOriginalUri', originalUri);
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    }
  };
  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={() => {
        console.log('on auth required');
      }}
    >
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/Home" exact component={App} />
        <Route path="/Harvested" exact component={App} />
        <Route path="/Contracts" exact component={App} />
        <Route path="/AdvPayment" exact component={App} />
        <Route path="/callback" exact component={LoginCallback} />
      </Switch>
    </Security>
  );
};

const AppWithRouterAccess = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </Provider>
);
ReactDOM.render(<AppWithRouterAccess />, document.getElementById('root'));
