import React, { useState, useEffect, useCallback } from 'react';
import { useStyles } from '../components/Desktop/Harvested/Styles';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Typography,
  Select,
  MenuItem,
  createTheme,
  useTheme,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import {
  BrowserView,
  MobileView,
  isBrowser,
  MobileOnlyView,
  TabletView,
  isTablet,
} from 'react-device-detect';
import HarvestedDataTable from '../components/Desktop/Harvested/HarvestedDataTable';
import { styles } from '../components/Desktop/Contracts/Styles';
import '../components/Desktop/Harvested/Harvested.css';
import Calendar from '../components/Desktop/Calendar/HarvestedCalendar';
import { Label } from 'reactstrap';
import { getSupplier } from '../services/api/supplier';
import { connect } from 'react-redux';
import { setAccessRole } from '../redux/actions/accessRole';
import { setSuppliers } from '../redux/actions/supplierData';
import { ThemeProvider } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getHarvestedPlants } from '../services/api/harvested';
import { useQuery } from '../utils/customHooks';
import { getUrlParam, setUrlParam } from '../utils/helperFunctions';
import { useHistory } from 'react-router-dom';

const defaultMaterialTheme = createTheme({
  typography: {
    fontSize: 13,
    fontFamily: 'Proxima Nova',
  },
});

const Harvested = props => {
  const history = useHistory();
  const query = useQuery();
  const { accessRole, setAccessRole } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [displayType, setDisplay] = useState(isBrowser ? '%' : 'Both');
  const [viewType, setView] = useState('Concise');
  const [detailedClicked, setDetailClicked] = useState(false);
  const [weeklySummaryFilter, setWeeklySummaryFilter] = useState({});
  const displayTypes = ['%', 'Head', 'Both'];
  const viewTypes = ['Concise', 'Complete'];
  const radioOptions = [
    { type: 'View', associatedArr: viewTypes, defaultValue: 'Concise' },
    { type: 'Display', associatedArr: displayTypes, defaultValue: '%' },
  ];
  const [loadingMessage, setLoadingMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedMonthYear, setSelectedMonthYear] = useState([]);
  const [selectedToDate, setToDate] = useState(new Date());
  const [selectedFromDate, setFromDate] = useState('');
  const [searchData, setSearchData] = useState(true);
  const [isFilterEmpty, setIsFilterEmpty] = useState(false);
  const [selectedBuyer, selectBuyer] = useState('');
  const [selectedSupplier, selectSupplier] = useState('');
  const [roles, setRoles] = useState([]);
  const [plants, setPlants] = useState([]);
  const [selectedPlant, selectPlant] = useState('');
  const [buyerNum, setBuyerNum] = React.useState('');
  const [supplierData, setSupplierData] = React.useState([]);
  const [searchClicked, setSearchClicked] = useState(false);

  const setHarvestedPlantsDD = useCallback(() => {
    if (!plants.length) {
      const arrPlants = [
        { plantNo: '000', name: 'All Plants', isSelected: false },
      ];
      getHarvestedPlants()
        .then(res => {
          selectPlant(arrPlants[0].name);
          if (res.length) {
            for (const i of res) {
              const plant = {
                plantNo: i.PLANT_CODE,
                name: `${i.PLANT_CODE} - ${i.PLANT_DESC.trim()}`,
                isSelected: false,
              };
              arrPlants.push(plant);
            }
            setPlants(arrPlants);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [plants]);

  useEffect(() => {
    const userRole = window.localStorage.getItem('userRole');
    const isBuyer = userRole.toLowerCase().includes('buyer');
    if (accessRole.length === 0) {
      const buyers = JSON.parse(window.localStorage.getItem('buyers'));
      if (
        !isBuyer &&
        buyers.length > 1 &&
        buyers[0].BUYER_NAME !== 'All Buyers'
      ) {
        buyers.unshift({
          BUYER_NAME: 'All Buyers',
          BUYER_NUMBER: 'ALL',
          BUYER_TYPE: 'B',
          EMAIL_ADDRESS: 'ALL',
        });
      }
      setAccessRole(buyers);
      selectBuyer(buyers[0]);
      fetchSupplier(`${buyers[0].BUYER_NUMBER} - ${buyers[0].BUYER_NAME}`);
    } else if (selectedBuyer === '') {
      selectBuyer(accessRole[0]);
      fetchSupplier(
        `${accessRole[0].BUYER_NUMBER} - ${accessRole[0].BUYER_NAME}`,
      );
    }
    setHarvestedPlantsDD();
  }, [accessRole, setAccessRole, setHarvestedPlantsDD, selectedBuyer]);

  const fetchSupplier = buyerData => {
    getSupplier(buyerData.split(' ')[0])
      .then(res => {
        const filterData = res.map(item => {
          for (var key in item) {
            if (item[key] !== null) {
              item[key] = item[key].toString().trim();
            }
          }
          return item;
        });
        setBuyerNum(buyerData.split(' ')[0]);
        setSupplierData(filterData);
      })
      .catch(err => {
        setSupplierData([
          {
            SUPNUMBER: ' ',
            SUPLASTNAME: 'No Suppliers Found',
            SUPFIRSTNAME: '  ',
          },
        ]);
        console.log(err);
      });
  };

  const setWeeklySummary = useCallback(
    array => {
      const arrEmpty = [];
      Object.keys(array).forEach(key => {
        if (array[key] !== '') {
          setWeeklySummaryFilter(array);
        } else {
          arrEmpty.push(key);
        }
      });
      // If the array of empty fields has nothing in it, the search was valid
      let isSearchValid;
      if (arrEmpty.length) {
        setIsFilterEmpty(true);
        setSearchData(true);
        isSearchValid = false;
      } else {
        setIsFilterEmpty(false);
        setSearchData(false);
        isSearchValid = true;
      }
      return isSearchValid;
    },
    [setWeeklySummaryFilter, setIsFilterEmpty, setSearchData],
  );

  const runSearch = useCallback(
    weeklySummary => {
      setSearchClicked(true);
      return setWeeklySummary(weeklySummary);
    },
    [setSearchClicked, setWeeklySummary],
  );

  const handleSearch = () => {
    const weeklySummary = {
      Buyer: buyerNum,
      Plants: selectedPlant.split(' ')[0],
      Supplier: selectedSupplier ? selectedSupplier.SUPNUMBER : '',
      StartDate: selectedFromDate,
      EndDate: selectedToDate,
    };
    const isSearchValid = runSearch(weeklySummary);
    if (isSearchValid) {
      // Update query params
      setUrlParam(query, history, '/Harvested', 'search', weeklySummary);
    }
  };

  const errorMessaging = () => {
    if (errorMessage !== '') {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Alert severity="warning" style={{ marginTop: 50 }}>
              {errorMessage}
            </Alert>
          </Grid>
        </Grid>
      );
    } else {
      return <div />;
    }
  };

  const loadingMessaging = () => {
    if (loadingMessage !== '') {
      return (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Alert severity="info" style={{ marginTop: 50 }}>
              {loadingMessage}
            </Alert>
          </Grid>
        </Grid>
      );
    } else {
      return <div />;
    }
  };

  useEffect(() => {
    setRoles(accessRole);
    if (accessRole?.length === 1) {
      selectBuyer(accessRole[0]);
      fetchSupplier(
        `${accessRole[0]?.BUYER_NUMBER} - ${accessRole[0]?.BUYER_NAME}`,
      );
    }
  }, [accessRole]);

  useEffect(() => {
    selectSupplier('');
  }, [selectedBuyer]);

  const search = getUrlParam(query, 'search');
  useEffect(() => {
    if (search !== null) {
      // If there is a search query param, update the UI accordingly
      runSearch(search);
    } else {
      // If there wasn't a search query param, the UI should render search page
      setSearchData(true);
    }
    // Ignore the linter error. We only want to update when the queryString changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.toString()]);

  return (
    <div className={'harvested'}>
      {searchData ? (
        <>
          <div className={'heading'}>
            <h5> History Weekly </h5>
          </div>
          <Grid
            container
            direction="row"
            spacing={1}
            justify="center"
            style={matchesSM ? styles.grid1Mobile : styles.grid1Browsers}
            lg
          >
            <Grid
              item
              container
              direction="column"
              style={{
                textAlign: 'left',
                width: matchesSM ? '15em' : '30em',
              }}
            >
              <Typography
                className={classes.inputTitle}
                style={{ marginTop: 15 }}
              >
                1. Buyer:
              </Typography>
              <ThemeProvider theme={defaultMaterialTheme}>
                <Autocomplete
                  id="buyer-autocomplete"
                  className="input-bkgrd"
                  options={roles}
                  disableClearable
                  autoHighlight
                  getOptionLabel={option =>
                    option
                      ? `${
                          option.BUYER_NUMBER === 'ALL'
                            ? ''
                            : option.BUYER_NUMBER
                        }${
                          option.BUYER_NUMBER === 'ALL' ? '' : '-'
                        }  ${option?.BUYER_NAME?.trim()}`
                      : ''
                  }
                  value={selectedBuyer}
                  noOptionsText="No labels"
                  getOptionSelected={(option, value) =>
                    option.BUYER_NUMBER === value.BUYER_NUMBER
                  }
                  onChange={(event, newValue) => {
                    selectBuyer(newValue ? newValue : '');
                    fetchSupplier(
                      `${newValue?.BUYER_NUMBER} - ${newValue?.BUYER_NAME}`,
                    );
                    setSearchClicked(false);
                    setDisplay(isBrowser ? '%' : 'Both');
                  }}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      style={{
                        paddingTop: '5px',
                      }}
                    />
                  )}
                />
              </ThemeProvider>
              <Typography
                className={classes.inputTitle}
                style={{ marginTop: 15 }}
              >
                2. Supplier:
              </Typography>
              <ThemeProvider theme={defaultMaterialTheme}>
                <Autocomplete
                  id="suplier-auto-complete"
                  className="input-bkgrd"
                  options={supplierData}
                  disableClearable
                  autoHighlight
                  getOptionLabel={item =>
                    item
                      ? item.SUPLASTNAME === 'No Suppliers Found'
                        ? `${item?.SUPNUMBER} - ${item?.SUPLASTNAME?.trim()}`
                        : `${item.SUPNUMBER} - ${item.SUPLASTNAME} ${item.SUPFIRSTNAME}`
                      : ''
                  }
                  getOptionSelected={(option, value) =>
                    option.SUPNUMBER === value.SUPNUMBER
                  }
                  value={selectedSupplier}
                  classes={{ root: classes.select }}
                  style={{ width: '100%' }}
                  onChange={(event, newValue) => {
                    selectSupplier(newValue ? newValue : '');
                    setSearchClicked(false);
                    setDisplay(isBrowser ? '%' : 'Both');
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      size="small"
                      style={{
                        paddingTop: '5px',
                      }}
                    />
                  )}
                />
              </ThemeProvider>
              <Typography
                className={classes.inputTitle}
                style={{ marginTop: 15 }}
              >
                3. Select Plant:
              </Typography>
              <ThemeProvider theme={defaultMaterialTheme}>
                <Select
                  className="input-bkgrd"
                  value={selectedPlant}
                  onChange={e => {
                    selectPlant(e.target.value);
                    setSearchClicked(false);
                    setDisplay(isBrowser ? '%' : 'Both');
                  }}
                  classes={{ root: classes.select }}
                  style={{ marginTop: 10 }}
                >
                  {plants.map(item => {
                    return (
                      <MenuItem key={item.plantNo} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </ThemeProvider>
              <Typography
                className={classes.inputTitle}
                style={{ marginTop: 10 }}
              >
                4. Select Date Range:
              </Typography>
              <Calendar
                selectedMonthYear={selectedMonthYear}
                changeMonthYear={item => {
                  setSelectedMonthYear(item);
                }}
                onChange={e => {
                  setSearchClicked(false);
                  setToDate(e.target.value);
                  setFromDate(e.target.value);
                  console.log('to change: ', e);
                }}
                fromDate={setFromDate}
                toDate={setToDate}
                dateFrom={selectedFromDate}
                dateTo={selectedToDate}
                click={searchClicked}
                resetDisplay={() => setDisplay(isBrowser ? '%' : 'Both')}
              />
            </Grid>
          </Grid>
          {isFilterEmpty && searchClicked ? (
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item>
                <Alert severity="error">
                  Not all of the fields have a value
                </Alert>
              </Grid>
            </Grid>
          ) : (
            <div />
          )}

          <Grid container justify="center" alignItems="center">
            <div style={styles.prefilledFooter}>
              <label
                className={'btn-clear'}
                onClick={() => {
                  selectPlant('');
                  selectBuyer('');
                  selectSupplier('');
                  setIsFilterEmpty(false);
                  setSearchClicked(false);
                }}
              >
                Clear
              </label>
              <label
                style={{ marginLeft: 50 }}
                className={'btn-search'}
                onClick={handleSearch}
              >
                Search
              </label>
            </div>
          </Grid>
        </>
      ) : (
        <>
          {isBrowser || isTablet ? (
            <div>
              <div>
                <label
                  style={{ marginLeft: 50, marginTop: 20 }}
                  className={'btn-backto'}
                  onClick={() => {
                    history.goBack();
                    setErrorMessage('');
                    setLoadingMessage('');
                  }}
                >
                  <div className={'arrow-left'}></div>
                  {detailedClicked ? 'Back to Weekly Comparision' : 'Back'}
                </label>
                <div className={'buyer-supplier'}>
                  <h5>{`${selectedBuyer?.BUYER_NUMBER} - ${selectedBuyer?.BUYER_NAME}`}</h5>
                  <h5>
                    {selectedSupplier?.SUPLASTNAME === 'No Suppliers Found'
                      ? `${
                          selectedSupplier?.SUPNUMBER
                        } - ${selectedSupplier?.SUPLASTNAME?.trim()}`
                      : `${selectedSupplier?.SUPNUMBER} - ${selectedSupplier?.SUPLASTNAME} ${selectedSupplier.SUPFIRSTNAME}`}
                  </h5>
                </div>

                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <Typography className={classes.displayText}>
                      Display:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <RadioGroup
                      row
                      defaultValue="%"
                      style={{ marginTop: '10px' }}
                      onChange={e => setDisplay(e.target.value)}
                    >
                      <FormControlLabel
                        checked={displayType === '%'}
                        value="%"
                        control={<Radio color="black" size="small" />}
                        label="%"
                        className={classes.displayRB}
                      />
                      <FormControlLabel
                        checked={displayType === 'Head'}
                        value="Head"
                        control={<Radio color="black" size="small" />}
                        label="Head"
                        className={classes.displayRB}
                      />
                      <FormControlLabel
                        checked={displayType === 'Both'}
                        value="Both"
                        control={<Radio color="black" size="small" />}
                        label="Both"
                        className={classes.displayRB}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center">
                  <Grid
                    item
                    style={{
                      maxWidth: '90%',
                      marginBottom: 30,
                      overflowX: 'hidden',
                    }}
                  >
                    <HarvestedDataTable
                      display={displayType}
                      detailClicked={detailedClicked}
                      setDetailClicked={v => setDetailClicked(v)}
                      weeklySummaryFilter={weeklySummaryFilter}
                      errorHandle={setErrorMessage}
                      loadingHandle={setLoadingMessage}
                    />
                  </Grid>
                </Grid>
                {loadingMessaging()}
                {errorMessaging()}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <MobileOnlyView>
            <div
              style={{
                display: 'block',
                marginLeft: 20,
                height: '80%',
              }}
            >
              <label
                style={{ marginBottom: 50 }}
                className={'btn-backto'}
                onClick={() => {
                  detailedClicked
                    ? setDetailClicked(false)
                    : setSearchData(true);
                    setView('Concise');
                    history.goBack();
                    setErrorMessage('');
                    setLoadingMessage('');
                }}
              >
                <div className={'arrow-left'}></div>
                {detailedClicked ? 'Back to Weekly Comparision' : 'Back'}
              </label>
              <div className={'buyer-supplier'}>
                <h5>{`${selectedBuyer?.BUYER_NUMBER} - ${selectedBuyer?.BUYER_NAME}`}</h5>
                <h5>
                  {selectedSupplier?.SUPLASTNAME === 'No Suppliers Found'
                    ? `${
                        selectedSupplier?.SUPNUMBER
                      } - ${selectedSupplier?.SUPLASTNAME?.trim()}`
                    : `${selectedSupplier?.SUPNUMBER} - ${selectedSupplier?.SUPLASTNAME} ${selectedSupplier.SUPFIRSTNAME}`}
                </h5>
              </div>
              <div style={{ marginBottom: 100 }}>
                {radioOptions.map(obj => (
                  <div style={styles.radioOptions}>
                    <RadioGroup
                      row
                      defaultValue={obj.defaultValue}
                      onChange={e =>
                        obj.type === 'Display'
                          ? setDisplay(e.target.value)
                          : setView(e.target.value)
                      }
                    >
                      <Label
                        className={classes.displayText}
                        style={styles.displayText}
                      >
                        {`${obj.type} : `}
                      </Label>
                      {obj.associatedArr.map(item => (
                        <FormControlLabel
                          value={item}
                          control={<Radio color="black" size="small" />}
                          label={item}
                          className={classes.displayRB}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                ))}
                <Grid
                  container
                  justify="start"
                  alignItems="start"
                  style={{ marginBottom: 100 }}
                >
                  <Grid item style={{ maxWidth: '100vw' }}>
                    <HarvestedDataTable
                      display={displayType}
                      viewType={viewType}
                      detailClicked={detailedClicked}
                      setDetailClicked={v => setDetailClicked(v)}
                      weeklySummaryFilter={weeklySummaryFilter}
                      errorHandle={setErrorMessage}
                      loadingHandle={setLoadingMessage}
                    />
                  </Grid>
                </Grid>
                {loadingMessaging()}
                {errorMessaging()}
              </div>
            </div>
          </MobileOnlyView>
        </>
      )}
    </div>
  );
};
const mapStateToProps = state => ({
  accessRole: state.accessRole.accessRole,
  suppliers: state.suppliers.suppliers,
});
const mapDispatchToProps = dispatch => ({
  setAccessRole: roles => dispatch(setAccessRole(roles)),
  setSuppliers: suppliers => dispatch(setSuppliers(suppliers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Harvested);
